import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Overhead Squat 4-4-4-4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 EMOM of:`}</p>
    <p>{`1-Snatch Grip DL`}</p>
    <p>{`1-Hang Power Snatch`}</p>
    <p>{`1-Full Snatch`}</p>
    <p>{`(using 80% 1rm of weakest movement)`}</p>
    <p>{`then at minute 12:00:`}</p>
    <p>{`5:00 EMOM of:`}</p>
    <p>{`10-Box Jump Overs (24/20)`}</p>
    <p>{`10-Reverse Lunges holding KB to chest (53/35, 5 lunges/leg)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no CrossFit Kids today.`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`The 2017 CrossFit Games are live this week! We’ll be having a
members get together at the Barnett’s house Sunday, August 6th, from
12-6. We’ll eat, watch the Games, and swim if you’d like. This is a
family friendly event so bring the kids! Please bring a side dish (if
your last name starts with A-M) or dessert (if your last name starts
with N-Z). We’ll supply the meat and drinks. Email, call or text Daniel
to RSVP and for directions.  Sunday’s 12:30 class will be cancelled for
the party.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      